<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useChecklistStore } from '@/stores/checklist';
import { useRoleStore } from '@/stores/role';
import { useDomainStore } from '@/stores/domain';
import Modal from '@/components/Modal.vue';
import ChecklistForm from '@/components/ChecklistForm.vue';
import ChecklistDetail from '@/components/ChecklistDetail.vue';
import { Checklist } from '@/types/checklist';

const checklistStore = useChecklistStore();
const roleStore = useRoleStore();
const domainStore = useDomainStore();

const isModalOpen = ref(false);
const isChecklistModalOpen = ref(false);
const selectedChecklist = ref<Checklist | null>(null);

onMounted(() => {
    checklistStore.fetchChecklists();
    roleStore.fetchRoles();
    domainStore.fetchDomains();
});

const openModal = () => {
    isModalOpen.value = true;
};

const closeModal = () => {
    isModalOpen.value = false;
    checklistStore.fetchChecklists();
};

const openChecklistModal = (checklist: Checklist) => {
    selectedChecklist.value = checklist;
    isChecklistModalOpen.value = true;
};

const closeChecklistModal = () => {
    isChecklistModalOpen.value = false;
    selectedChecklist.value = null;
    checklistStore.fetchChecklists();
};
</script>

<template>
    <div class="min-h-full">
        <header class="bg-white shadow">
            <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                <h1 class="text-3xl font-bold tracking-tight text-gray-900">
                    Checklists
                </h1>
            </div>
        </header>
        <main>
            <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                <div class="px-4 sm:px-6 lg:px-8">
                    <div class="sm:flex sm:items-center">
                        <div class="sm:flex-auto"></div>
                        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <button
                                @click="openModal"
                                type="button"
                                class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Create Checklist
                            </button>
                        </div>
                    </div>
                    <div class="mt-8 flow-root">
                        <div
                            v-if="checklistStore.checklists.length === 0"
                            class="text-gray-500 text-center py-4"
                        >
                            No checklists created yet
                        </div>
                        <div
                            v-else
                            class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"
                        >
                            <div
                                class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                            >
                                <table
                                    class="min-w-full divide-y divide-gray-300"
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                            >
                                                Title
                                            </th>
                                            <th
                                                scope="col"
                                                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Created At
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="divide-y divide-gray-200">
                                        <tr
                                            v-for="checklist in checklistStore.checklists"
                                            :key="checklist.id"
                                            @click="
                                                openChecklistModal(checklist)
                                            "
                                            class="cursor-pointer hover:bg-gray-50"
                                        >
                                            <td
                                                class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                                            >
                                                {{ checklist.name }}
                                            </td>
                                            <td
                                                class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                            >
                                                {{
                                                    new Date(
                                                        checklist.created_at,
                                                    ).toLocaleString()
                                                }}
                                            </td>
                                            <td
                                                class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                            >
                                                <button
                                                    @click="
                                                        openChecklistModal(
                                                            checklist,
                                                        )
                                                    "
                                                    type="button"
                                                    class="text-indigo-600 hover:text-indigo-900"
                                                >
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <Modal :is-open="isModalOpen" @close="closeModal" :is-creating="true">
        <ChecklistForm @close="closeModal" />
    </Modal>
    <Modal :is-open="isChecklistModalOpen" @close="closeChecklistModal">
        <ChecklistDetail
            v-if="selectedChecklist"
            :checklist="selectedChecklist"
            @close="closeChecklistModal"
        />
    </Modal>
</template>
