<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useChecklistStore } from '@/stores/checklist';
import { Checklist, ChecklistSection } from '@/types/checklist';

const props = defineProps<{
    checklist: Checklist;
}>();

const emit = defineEmits<{
    (e: 'close'): void;
}>();

const checklistStore = useChecklistStore();
const checkedItems = ref(new Map());

onMounted(async () => {
    const savedChecks = await checklistStore.fetchChecklistProgress(
        props.checklist.id,
    );
    checkedItems.value = new Map(
        savedChecks.map(check => [check.item_id, check]),
    );
});

const toggleCheck = async (headingIndex: number, itemIndex: number) => {
    const itemId = `${headingIndex}-${itemIndex}`;
    const isChecked = !checkedItems.value.get(itemId)?.checked;

    await checklistStore.updateChecklistProgress({
        checklist_id: props.checklist.id,
        item_id: itemId,
        checked: isChecked,
        checked_at: isChecked ? new Date().toISOString() : null,
    });

    // Update local state
    checkedItems.value.set(itemId, {
        item_id: itemId,
        checked: isChecked,
        checked_at: isChecked ? new Date().toISOString() : null,
    });
};
</script>

<template>
    <div class="p-4">
        <div class="flex justify-between items-center mb-6">
            <h2 class="text-2xl font-bold text-gray-900">
                {{ checklist.name }}
            </h2>
            <button
                @click="emit('close')"
                class="text-gray-400 hover:text-gray-500"
            >
                <span class="sr-only">Close</span>
                <svg
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </button>
        </div>

        <div class="space-y-6">
            <div
                v-for="(
                    section, headingIndex
                ) in checklist.checklist as ChecklistSection[]"
                :key="section.heading"
                class="space-y-4"
            >
                <h3 class="text-lg font-semibold text-gray-900">
                    {{ section.heading }}
                </h3>
                <div class="space-y-2">
                    <div
                        v-for="(item, itemIndex) in section.items"
                        :key="`${headingIndex}-${itemIndex}`"
                        class="flex items-start space-x-3"
                    >
                        <div class="flex items-center h-5">
                            <input
                                :id="`${headingIndex}-${itemIndex}`"
                                type="checkbox"
                                :checked="
                                    checkedItems.get(
                                        `${headingIndex}-${itemIndex}`,
                                    )?.checked
                                "
                                @change="toggleCheck(headingIndex, itemIndex)"
                                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label
                                :for="`${headingIndex}-${itemIndex}`"
                                class="text-sm text-gray-700"
                            >
                                {{ item }}
                            </label>
                            <span
                                v-if="
                                    checkedItems.get(
                                        `${headingIndex}-${itemIndex}`,
                                    )?.checked_at
                                "
                                class="text-xs text-gray-500"
                            >
                                Checked on:
                                {{
                                    new Date(
                                        checkedItems.get(
                                            `${headingIndex}-${itemIndex}`,
                                        ).checked_at,
                                    ).toLocaleString()
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
