<script setup lang="ts">
import { onMounted } from 'vue';
import { useAuthStore } from '@/stores/auth';
import NavBar from '@/components/NavBar.vue';

const authStore = useAuthStore();

onMounted(async () => {
    await authStore.checkUser();
});
</script>

<template>
    <div class="min-h-screen bg-gray-100">
        <NavBar v-if="authStore.user" />
        <main>
            <router-view></router-view>
        </main>
    </div>
</template>
